import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { LobIconUtil, CONSTANTS, LOBConstants, WMICRichTextUtil, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useModal } from '@jutro/components';
import {
    WMICControllerUtil,
    WMICPolicyChangeStateService
} from 'wmic-capability-policychange';
import { useHistory } from 'react-router-dom';
import { WMICManagePaymentPlan, WMICPolicyDetailsUtil, WMICModal } from 'gw-capability-policy-react';
import { WMICButton } from 'wmic-components-platform-react';
import classNames from 'classnames';
import metadata from './WMICChangeTypeSelectorPAComponent.metadata.json5';
import messages from './WMICChangeTypeSelectorPAComponent.messages';
import styles from './WMICChangeTypeSelectorPAComponent.module.scss';

function WMICChangeTypeSelectorPAComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        billingData,
        setLoadingState,
        loadingState
    } = props;
    const translator = useTranslator();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [selectedValue, updateSelectedValue] = useState('');
    const [submitPath, updateSubmitPath] = useState(undefined);
    const [showWarningMessage, updateShowWarningMessage] = useState(false);
    const [showValidationMessage, updateShowValidationMessage] = useState(false);
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const history = useHistory();
    const PAYMENT_PLAN_MODAL_NAME = 2;
    _.set(billingData, 'policyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
    _.set(billingData, 'renewalPolicyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
    const [isDesignated, setIsDesignated] = useState(false);
    //Feature Flags fetures -- starts//
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [ldFlags, setLdFlags] = useState([]);
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);
    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    const getFeatureAvailability = useCallback((featureName) => {
        const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);
        return response;
    }, [ldFlags]);

    useEffect(() => {
        initFeatureFlags();
    }, [initFeatureFlags])
    //Feature Flags fetures -- end//

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('changeTypeSelectorHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const hasOverDue = useCallback(() => {
        return billingData.amountOverDue && billingData.amountOverDue.amount > 0;
    }, [billingData.amountOverDue]);

    const hasUnbilledAmount = useCallback(() => {
        return billingData.unbilledAmount && billingData.unbilledAmount.amount > 0;
    }, [billingData.unbilledAmount])

    const availableOptions = useMemo(() => {
        let options = [
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN,
                displayName: translator(messages.paymentPlan)
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.CHANGE_ADDRESS,
                displayName: translator(messages.changeAddress)
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING,
                displayName: translator(messages.financeLeasing),
                options: [
                    CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING_ADD,
                    CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING_CHANGE,
                    CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING_DELETE,
                    CONSTANTS.POLICY_CHANGE_TYPE.FINANCE_LEASING_SEND
                ]
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.ADD_REMOVE_DRIVER,
                displayName: translator(messages.addOrRemoveDriver)
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.DELETE_VEHICLE,
                displayName: translator(messages.deleteVehicle)
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE,
                displayName: translator(messages.roadsideAssistance),
                options: [
                    CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE_ADD,
                    CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE_DELETE
                ]
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.ADJUST_COVERAGES,
                displayName: translator(messages.adjustCoverages)
            },
            {
                id: CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES,
                displayName: translator(messages.otherChanges)
            }
        ];

        const isCalifornia = policyVM.currentPeriod.jurisdiction_WMIC === CONSTANTS.JURISDICTIONS.CA;
        const isRenewalFullPay = policyVM.hasOwnProperty('renewedPeriod') && billingData.paymentPlan === "Full Pay";
        const hidePaymentManaging = isRenewalFullPay && isCalifornia;

        if (hidePaymentManaging || hasOverDue() || !hasUnbilledAmount()) {
            options = options.filter((opt) => opt.id !== CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN);
        }

        if (isDesignated) {
            options.splice(options.length - 1, 0, {
                code: CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON,
                name: (
                    <div className={styles.optionTextContainer}>
                        {translator(messages.designatedPerson)}
                    </div>
                ),
                displayName: translator(messages.designatedPerson),
                options: [
                    CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_ADD,
                    CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_CHANGE,
                    CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_DELETE,
                ]
            });
        }
        return options;
    }, [translator, policyVM, billingData.paymentPlan, hasOverDue, hasUnbilledAmount, isDesignated]);

    const getChangeTypeSelectorTitle = () => {
        const title = translator(messages.selectAPolicyChange);

        return (
            <h2 className={styles['wmic-policy-title']} aria-label={translator(messages.selectAPolicyChangeAria)}>{title}</h2>
        );
    };

    const getAccountHolder = (polData) => {
        const contacts = _.get(polData, 'currentPeriod.contacts');
        return contacts.filter((contact) => { return contact.contact.accountHolder === true; })[0];
    };

    useEffect(() => {
        setLoadingState(true);
        const controllerUtilProps = {
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyType: policyType,
            accountInfo: getAccountHolder(policyVM),
            authHeader: authHeader,
        };

        WMICControllerUtil.initVars(controllerUtilProps);
        WMICPolicyChangeStateService.clearChangeRequest();
        // eslint-disable-next-line max-len
        AccountBillingDetailsService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader)
            .then((accBillingData) => {
                const isCalifornia = accBillingData.accountSummary.country === CONSTANTS.COUNTRY.US
                    && accBillingData.accountSummary.state === CONSTANTS.STATE.CA
                setIsDesignated(isCalifornia);
            }).finally(() => {
                setLoadingState(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectOption = useCallback((value) => {
        updateSelectedValue(value);
        updateShowValidationMessage(false);
        if (value === CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES || value === CONSTANTS.POLICY_CHANGE_TYPE.ADD_REMOVE_DRIVER) {
            updateSubmitPath(undefined);
        } else {
            updateSubmitPath(`/account-policy-change/${policyNumber}/${termNumber}/auto/${value}`);
        }
        const subOptions = availableOptions.find((option) => option.id === value && option.options !== undefined );
        const showWarning = WMICControllerUtil.showWarningMessage(policyNumber, value, subOptions);
        updateShowWarningMessage(showWarning);
    }, [availableOptions, policyNumber, termNumber]);

    const onSubmit = useCallback((selectedOption) => {
        if (!selectedOption) {
            updateShowValidationMessage(true);
            return;
        }
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN) {
            return modalApi.showModal(
                <WMICManagePaymentPlan
                    billingData={billingData}
                    stepName={PAYMENT_PLAN_MODAL_NAME}
                    updateBillingDetails={_.noop}
                />
            );
        }
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES) {
            return modalApi.showModal(
                <WMICModal
                    id="callUsModal"
                    modalHeader={translator(messages.callUs)}
                    modalBody={WMICRichTextUtil.translateRichText(translator(messages.changesUnavailableOnline))}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.close)}
                />
            );
        }
        //Feature flag for ADDREMOVEDRIVER is being checked both conditions here as We dont have different flows.
        //When we implement this in furutre, the condition for popup has to be updated accoridngly. 
        //Now both flows(even addremovedriver is "on" or "off"), we are showing same popup.
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.ADD_REMOVE_DRIVER && (getFeatureAvailability(featureFlags.ADDREMOVEDRIVER).isAvailable || !getFeatureAvailability(featureFlags.ADDREMOVEDRIVER).isAvailable)) {
            return modalApi.showModal(
                <WMICModal
                    id="callUsModal"
                    modalHeader={translator(messages.callUs)}
                    modalBody={WMICRichTextUtil.translateRichText(translator(messages.changesUnavailableOnline))}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.close)}
                />
            );
        }
        return history.push(submitPath);
    }, [history, submitPath, translator, billingData]);

    const getWarning = () => {
        return (
            <div className={styles['ww-notification-message']}>
                <h3 className={classNames(styles['ww-notification-message-header'], styles['ww-status-warning'])}>
                    <i className="fa fa-exclamation-triangle" />
                    {translator(messages.warningMessageTitle)}
                </h3>
                <div className={styles['ww-notification-message-body']}>
                    {translator(messages.warningMessageBody)}
                </div>
            </div>
        );
    };

    const getSubmitButtonTrackButtonIdentifier = (value) => {
        let returnValue = '';
        const selectedPolicyChange = availableOptions.find((option) => option.id === value || option.code === value);
        if (selectedPolicyChange?.id === CONSTANTS.POLICY_CHANGE_TYPE.ADD_REMOVE_DRIVER) {
            returnValue= translator(messages.policyChangeSelectTrackButtonIdAddRemoveDriver);
        }else if (selectedPolicyChange) {
            returnValue = translator(messages.policyChangeSelectTrackButtonIdentifier, {selectedChange: selectedPolicyChange.displayName})
        }
        return returnValue;
    }

    const overrideProps = {
        changeTypeSelectorContainer: {
            visible: !loadingState
        },
        changeTypeSelectorTitle: {
            content: getChangeTypeSelectorTitle()
        },
        radioButtonGroupAria: {
            content: translator(messages.policyChangeSelectContentAria)
        },
        warningMessageContainer: {
            visible: showWarningMessage,
            content: getWarning()
        },
        radioButtonGroup: {
            availableValues: availableOptions,
            onValueChange: (value) => handleSelectOption(value),
            value: selectedValue
        },
        cancelButton: {
            onClick: () => history.push(`/account-policy-details/${policyNumber}/${termNumber}`)
        },
        submitButton: {
            onClick: () => onSubmit(selectedValue),
            trackButtonIdentifier: getSubmitButtonTrackButtonIdentifier(selectedValue)
        },
        validationMessage :{
            visible: showValidationMessage,
            content: translator(messages.validation)
        },
        californiaDisclaimerParagraph: {
            visible: billingData?.accountSummary?.state === CONSTANTS.STATE.CA,
            content: WMICRichTextUtil.translateRichText(translator(messages.legalDisclaimerCA))
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeTypeSelectorPAComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    billingData: PropTypes.shape({}).isRequired
};

export default WMICChangeTypeSelectorPAComponent;