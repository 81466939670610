import { defineMessages } from 'react-intl';

export default defineMessages({
    apply: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Apply",
        defaultMessage: "Apply"
    },
    dateRequested: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Date Ordered",
        defaultMessage: "Date Ordered"
    },
    dateReceived: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Date Received",
        defaultMessage: "Date Received"
    },
    orderStatus: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Status",
        defaultMessage: "Status"
    },
    requested: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Requested",
        defaultMessage: "Requested"
    },
    save: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Save",
        defaultMessage: "Save"
    },
    sourceSystem: {
        id: "wmic-pe-capability-gateway-common-react.loss-history.Source",
        defaultMessage: "Source"
    },

});
