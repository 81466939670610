import { defineMessages } from 'react-intl';

export default defineMessages({
    insurerName: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.insurerName",
        defaultMessage: "Insurer"
    },
    policy: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.policy",
        defaultMessage: "Policy"
    },
    riskType: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.riskType",
        defaultMessage: "Risk Type"
    },
    claimHistoryReportHeading: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.Claims History Report / Letter of Experience",
        defaultMessage: "Claims History Report / Letter of Experience"
    },
    claimHistoryReportDate: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.Claims history report / Letter of experience date",
        defaultMessage: "Claims history report / Letter of experience date"
    },
    autoPlusHeading: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.AutoPlus",
        defaultMessage: "AutoPlus"
    },
    autoPlusDate: {
        id: "policy.pa.views.pa-drivers-details.driver-policy-history.AutoPlus Date",
        defaultMessage: "Date AutoPlus ordered"
    },
    dateRequested: {
        id: "policy.pa.views.pa-drivers-details.driver-loss-history-view.Date Ordered",
        defaultMessage: "Date Ordered"
    },
    dateReceived: {
        id: "policy.pa.views.pa-drivers-details.driver-loss-history-view.Date Received",
        defaultMessage: "Date Received"
    },
    orderStatus: {
        id: "policy.pa.views.pa-drivers-details.driver-loss-history-view.Status",
        defaultMessage: "Status"
    },
    source: {
        id: "policy.pa.views.pa-drivers-details.driver-loss-history-view.Source",
        defaultMessage: "Source"
    },
});
