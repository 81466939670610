/* eslint-disable max-len */
import React, {
    Fragment,
    useContext,
    useState,
    useCallback,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { LobIconUtil, CONSTANTS, WMICTempStorageService } from 'wmic-portals-utils-js';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useModal } from '@jutro/components';
import WMICCancelEditCoveragesModal from './WMICCancelEditCoveragesModal/WMICCancelEditCoveragesModal';
import WMICErrorModal from '../WMICErrorModal/WMICErrorModal';
import WMICPreparingQuoteLoader from '../WMICPreparingQuoteLoader/WMICPreparingQuoteLoader';
import { WMICEndorsementUtil } from 'wmic-capability-policychange';
import { EndorsementService } from 'gw-capability-policychange';
import WMICCoverageDetail from './WMICCoverageDetail/WMICCoverageDetail';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICButton } from 'wmic-components-platform-react';
import { PolicyChange, WMICPolicyChangesVehicleVM } from 'gw-capability-policychange-common-react';
import { useAuthentication } from 'wmic-digital-auth-react';

import metadata from './WMICChangeDeductiblesOrCoveragesPAComponent.metadata.json5';
import messages from './WMICChangeDeductiblesOrCoveragesPAComponent.messages';
import styles from './WMICChangeDeductiblesOrCoveragesPAComponent.module.scss';

function WMICChangeDeductiblesOrCoveragesPAComponent(props) {
    const modalApi = useModal();
    const { policyVM, loadingState, setLoadingState } = props;
    const translator = useTranslator();
    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);
    const VIEW_MODES = {
        VEHICLE_LIST_VIEW: 'vehicle_list_view',
        EDIT_VEHICLE_COV_VIEW: 'edit_vehicle_cov_view',
        LOADING_QUOTE_VIEW: 'loading_quote_view'
    };
    const [viewMode, setViewMode] = useState(VIEW_MODES.VEHICLE_LIST_VIEW);
    const [model, setModel] = useState(undefined);
    const [originalModel, setOriginalModel] = useState(undefined);
    const [editedVehicle, setEditedVehicle] = useState(undefined);
    const [editableCoverages, setEditableCoverages] = useState(undefined);
    const [policyChangesVehicleVm, setPolicyChangesVehicleVm] = useState(undefined);
    const [offeringsBackup, setOfferingsBackup] = useState(undefined);
    const [policyChangesVehicleVmBackup, setPolicyChangesVehicleVmBackup] = useState(undefined);
    const [policyChanges, setPolicyChanges] = useState({vehicles: []});
    const [coverageValidationErrors, setCoverageValidationErrors] = useState([]);
    const { authHeader } = useAuthentication();
    const [isOpen, setIsOpen] = useState(false);

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('editVehicleCoveragesHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const getTitleWithIcon = (title, icon) => {
        return (
            <Fragment>
                <i className={`fa ${icon}`} />
                <h2 className={styles['wmic-policy-title']}>{title}</h2>
            </Fragment>
        );
    };

    const goBackHomeAction = () => {
        WMICTempStorageService.pop(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
        history.push('/home');
    };

    const showErrorModal = () => {
        modalApi.showModal(<WMICErrorModal onCancelEdit={goBackHomeAction} />).catch(_.noop);
    };

    const startOrResumePolicyChange = (policy, header) => {
        setLoadingState(true);
        const currentJobNumber = WMICTempStorageService.read(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
        if (currentJobNumber !== undefined) {
            EndorsementService.editQuotedPolicyChange_WMIC(currentJobNumber, authHeader).then((response) => {
                if (response.exceptions) {
                    _.reject(response.exception);
                } else {
                    const newPolicyChangeModel = viewModelService.create(new PolicyChange(response), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                    setModel(newPolicyChangeModel);
                    const initialLobData = response.originalLobData;
                    const initialPolicyChange = new PolicyChange(_.cloneDeep(response));
                    initialPolicyChange.lobData = initialLobData;
                    const initialPolicyChangeModel = viewModelService.create(initialPolicyChange, 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                    setOriginalModel(initialPolicyChangeModel);

                    const newPolicyChanges = {
                        vehicles: []
                    };

                    const vehicleCoverages = newPolicyChangeModel.lobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value;
                    const originalVehicleCoverages = newPolicyChangeModel.originalLobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value;

                    _.each(vehicleCoverages, (veh) => {
                        const originalVehicle = originalVehicleCoverages.find((orVeh) => orVeh.fixedId === veh.fixedId);
                        const policyChangeVehicleVM = new WMICPolicyChangesVehicleVM({ vehicleFixedId: veh.fixedId });
                        _.each(veh.coverages, (cov) => {
                            if (cov.terms.length > 0) {
                                const term = {
                                    coveragePublicId: cov.terms[0].coveragePublicID,
                                    coverageTermPatterCode: cov.terms[0].patternCode,
                                    coverageName: cov.terms[0].name,
                                    originalCoverageTermValue: originalVehicle.coverages.find((orCov) => 
                                        orCov.terms[0].coveragePublicID === cov.terms[0].coveragePublicID).terms[0].chosenTermValue,
                                    newCoverageTermValue: cov.terms[0].chosenTermValue,
                                    newlyUpdated: cov.terms[0].updated
                                }
                                policyChangeVehicleVM.coverageTermChanges.terms.push(term);
                            }
                        });

                        newPolicyChanges.vehicles.push(policyChangeVehicleVM);
                    });
                    setPolicyChanges(newPolicyChanges);
                }
            }).catch(() => {
                showErrorModal();
            }).finally(() => {
                setLoadingState(false);
            });
        } else {
            WMICEndorsementUtil.startPolicyChange(policy?.currentPeriod?.policyNumber, policy?.currentPeriod?.termNumber_WMIC, viewModelService, CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_ADJUST_COVERAGES, header)
            .then((data) => {
                const policyChangeModel = viewModelService.create(new PolicyChange(data.policyChange), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                setModel(policyChangeModel);
                const initialModel = viewModelService.create(new PolicyChange(_.cloneDeep(data.policyChange)), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                setOriginalModel(initialModel);
                WMICTempStorageService.push(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, data.policyChange.jobID);
            })
            .catch(() => {
                showErrorModal();
            })
            .finally(() => {
                setLoadingState(false);
            });
        }
    };

    useEffect(() => {
        startOrResumePolicyChange(policyVM, authHeader);
    }, []);

    const vehicleHasCoverageChanges = (fixedId) => {
        const coverageChanges = [];
        const item = policyChanges.vehicles.find(v => v.vehicleFixedId === fixedId);
        if (item?.coverageTermChanges?.terms?.length > 0) {
            _.each(item.coverageTermChanges.terms, (t) => {
                if (t.newCoverageTermValue !== t.originalCoverageTermValue) {
                    coverageChanges.push(t);
                }
            });
        }
        return coverageChanges.length > 0;
    };

    const editVehicleCoverages = (modelVm, fixedId) => {
        const vehicle = modelVm.lobData.personalAuto.coverables.vehicles.value.find((v) => v.fixedId === fixedId);
        setEditedVehicle(vehicle);

        if (policyChanges.vehicles.length === 0) {
            const policyChangesVehicleVm = new WMICPolicyChangesVehicleVM({ vehicleFixedId: vehicle.fixedId });
            setPolicyChangesVehicleVm(policyChangesVehicleVm);
            setPolicyChangesVehicleVmBackup(_.cloneDeep(policyChangesVehicleVm));
            policyChanges.vehicles.push(policyChangesVehicleVm);
            setPolicyChanges(policyChanges);
        } else {
            const vehiclePolicyChange = _.find(policyChanges.vehicles, (v) => { return v.vehicleFixedId === vehicle.fixedId; });
            if (!vehiclePolicyChange) {
                const policyChangesVehicleVm = new WMICPolicyChangesVehicleVM({ vehicleFixedId: vehicle.fixedId });
                setPolicyChangesVehicleVm(policyChangesVehicleVm);
                setPolicyChangesVehicleVmBackup(_.cloneDeep(policyChangesVehicleVm));
                policyChanges.vehicles.push(policyChangesVehicleVm);
                setPolicyChanges(policyChanges);
            } else {
                setPolicyChangesVehicleVm(vehiclePolicyChange);
                setPolicyChangesVehicleVmBackup(_.cloneDeep(vehiclePolicyChange));
            }
        }

        setOfferingsBackup(_.cloneDeep(modelVm.lobData.personalAuto.offerings.getElement(0).value));

        const vehicleCoverages = [];
        const originalVehicleCoverages = [];
        const modelVehicleCoverages = model.lobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages;

        _.each(modelVehicleCoverages.value, (vehCoverage, index) => {
            if (vehCoverage.fixedId === fixedId) {
                vehicleCoverages.push(modelVehicleCoverages.findFirstElement(modelVehicleCoverages.value[index]));
            }
        });

        const offerings = originalModel.originalLobData.personalAuto?.offerings.value ? originalModel.originalLobData.personalAuto.offerings : originalModel.lobData.personalAuto.offerings;
        const originalModelVehicleCoverages = offerings.getElement(0).coverages.vehicleCoverages;
        _.each(originalModelVehicleCoverages.value, (vehCoverage, index) => {
            if (vehCoverage.fixedId === vehicle.fixedId) {
                originalVehicleCoverages.push(originalModelVehicleCoverages.findFirstElement(originalModelVehicleCoverages.value[index]));
            }
        });

        setEditableCoverages(WMICEndorsementUtil.getEditableVehicleCoverages(vehicleCoverages[0].coverages, originalVehicleCoverages[0].coverages));
        setViewMode(VIEW_MODES.EDIT_VEHICLE_COV_VIEW);
    };

    const cancelEditCoverages = (modelVm, polChanges) => {
        setPolicyChangesVehicleVm(policyChangesVehicleVmBackup);

        _.each(polChanges.vehicles, (v) => {
            if (v.vehicleFixedId === editedVehicle.fixedId) {
                v.coverageTermChanges = policyChangesVehicleVmBackup.coverageTermChanges;
            }
        });

        modelVm.lobData.personalAuto.offerings.setElement(0, offeringsBackup);

        setEditedVehicle(undefined);
        WMICTempStorageService.pop(CONSTANTS.PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
        setViewMode(VIEW_MODES.VEHICLE_LIST_VIEW);
    };

    const checkCoveragesValid = () => {
        let errors = [];
        let collisionCoverageDeductible;
        let comprehensiveCoverageDeductible;
        if (editedVehicle?.vehicleType === CONSTANTS.VEHICLE_TYPES.TRAILER) {
            const collisionCoverage = _.find(editableCoverages.coverages, (cov) => {
                return cov.publicID.value.toLowerCase() === CONSTANTS.COVERAGE_NAMES.PA_COLLISION.toLowerCase();
            });
            if (collisionCoverage) {
                collisionCoverageDeductible = _.find(collisionCoverage.terms.value, (term) => {
                    return term.publicID.toLowerCase() === CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVDED.toLowerCase();
                });
            }
            const comprehensiveCoverage = _.find(editableCoverages.coverages, (cov) => {
                return cov.publicID.value.toLowerCase() === CONSTANTS.COVERAGE_NAMES.PA_COMPREHENSIVE.toLowerCase();
            });
            if (comprehensiveCoverage) {
                comprehensiveCoverageDeductible = _.find(comprehensiveCoverage.terms.value, (term) => {
                    return term.publicID.toLowerCase() === CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVDED.toLowerCase();
                });
            }
            if (collisionCoverageDeductible.chosenTermValue !== comprehensiveCoverageDeductible.chosenTermValue) {
                errors.push(translator(messages.trailerCoverageDeductiblesMustMatch));
            }
        }
        setCoverageValidationErrors(errors);
        return errors.length === 0;
    };

    const save = () => {
        if (checkCoveragesValid()) {
            setLoadingState(true);
            const lobCoverages = _.mapValues(model.lobData.value, lob => {
                return lob.offerings[0].coverages;
            });

            const updateCoveragesPromise = EndorsementService.updateCoverages(model.jobID.value, [], lobCoverages, authHeader).then(pc => {
                return new PolicyChange(pc);
            });

            updateCoveragesPromise.then((result) => {
                const updatedModel = viewModelService.create(new PolicyChange(result), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                setModel(updatedModel);

                const newPolicyChanges = {
                    vehicles: []
                };

                _.each(policyChangesVehicleVm.coverageTermChanges?.terms, (change) => {
                    change.newlyUpdated = false;
                });

                _.each(policyChanges.vehicles, (v) => {
                    if (v.vehicleFixedId === editedVehicle.fixedId) {
                        v.coverageTermChanges = policyChangesVehicleVm.coverageTermChanges;
                    }
                    newPolicyChanges.vehicles.push(v);
                });

                setPolicyChanges(newPolicyChanges);
                setLoadingState(false);
            });
            setViewMode(VIEW_MODES.VEHICLE_LIST_VIEW);
        }
    };

    const getVehiclesSection = (modelVm) => {
        const vehicles = modelVm?.lobData.personalAuto.coverables.vehicles.value;
        const content = vehicles?.map((v) => {
            return (
                <div className="ww-detail-row ww-detailed-row-boxed ww-row-flow">
                    <div className="ww-label ww-label-upper">
                        { `${v.year} ${v.make} ${v.model}` }
                    </div>
                    <div>
                        <WMICButton type="secondary" size="small" onClick={ () => { editVehicleCoverages(modelVm, v.fixedId); }}>
                            { translator(messages.editVehicleCoverage) }
                        </WMICButton>
                    </div>
                    { vehicleHasCoverageChanges(v.fixedId) && (
                        <div className="ww-row-notification">
                            <i className="fa fa-check"></i>
                            <div>
                                {translator(vehicles.length <= 1
                                    ? messages.yourChangesHaveBeenSavedClickContinueToGetAQuote
                                    : messages.yourChangesHaveBeenSavedClickContinueToGetAQuoteOrEditAnotherVehicle)}
                            </div>
                        </div>
                    )}
                </div>
            );
        });

        return (
            <Fragment>
                { content }
            </Fragment>
        );
    };

    const withdrawAndRedirect = () => {
        if (model) {
            setLoadingState(true);
            WMICEndorsementUtil.notTaken(model.jobID.value, authHeader).then((data) => {
                const model = viewModelService.create(new PolicyChange(data.policyChange), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO');
                setModel(model);
                goBackHomeAction();
            });
        }
    };

    const cancelPolicyEdit = () => {
        modalApi.showModal(<WMICCancelEditCoveragesModal isOpen={isOpen} onCancelEdit={withdrawAndRedirect} />).catch(_.noop);
    };

    const haveVehiclesCoverageChanges = () => {
        const coverageChanges = [];

        if (policyChanges?.vehicles) {
            _.each(policyChanges.vehicles, (v) => {
                if (v?.coverageTermChanges?.terms) {
                    _.each(v.coverageTermChanges.terms, (t) => {
                        if (t.newCoverageTermValue !== t.originalCoverageTermValue) {
                            coverageChanges.push(t);
                        }
                    });
                }
            });
        }
        return coverageChanges.length > 0;
    };

    const canQuote = () => {
        return haveVehiclesCoverageChanges();
    };

    const quoteAndReview = () => {
        setViewMode(VIEW_MODES.LOADING_QUOTE_VIEW);

        if (canQuote()) {
            
            EndorsementService.quoteEndorsement_WMIC(model.jobID.value, CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_ADJUST_COVERAGES, authHeader).then((policyChange) => {
                if (policyChange.exceptions && policyChange.exceptions.length > 0) {
                    setViewMode(VIEW_MODES.VEHICLE_LIST_VIEW);
                    showErrorModal();
                } else {
                    setModel(viewModelService.create(new PolicyChange(policyChange), 'pc', 'wmic.edge.us.capabilities.policychange.dto.PolicyChangeDataDTO'));
                    history.push(`/review-policy-change/${model.policyNumber.value}`);
                }
            }).catch(() => {
                setViewMode(VIEW_MODES.VEHICLE_LIST_VIEW);
                showErrorModal();
            });
        }
    };

    const getButtonsSection = () => {
        return (
            <Fragment>
                <p>
                    { translator(messages.youllBeAbleToPeviewYourUpdatedPremiumBeforeYouConfirmTheseChanges) }
                </p>
                <WMICButton type="outlined"
                    onClick={cancelPolicyEdit}>
                    { translator(messages.cancelEdit) }
                </WMICButton>
                <WMICButton type="primary"
                    disabled={!canQuote()}
                    onClick={quoteAndReview}>
                    { translator(messages.continue) }
                </WMICButton>
            </Fragment>
        );
    };

    const phoneTrigger = useCallback((event, phoneNumber) => {
        event.preventDefault();
        window.location = `tel:${phoneNumber}`;
    }, []);

    const getOriginalCoverage = (coverage) => {
        return _.find(editableCoverages.originalCoverages, (originalCoverage) => {
            return originalCoverage.publicID.value === coverage.publicID.value;
        });
    };

    const coverageTermUpdated = ({ terms }) => {
        setPolicyChangesVehicleVm(prev => ({ ...prev, coverageTermChanges: { ...prev.coverageTermChanges, terms } }));
    };

    const getCoverageDetail = (editableCov) => {
        const coverages = editableCov?.coverages;
        const content = coverages?.map((coverage) => {
            if (coverage.value.selected) {
                return (
                    <div className="ww-card-row ww-billing-subsection ww-billing-subsection-box">
                        <WMICCoverageDetail
                            coverage={coverage}
                            model={model}
                            getOriginalCoverage={getOriginalCoverage}
                            coverageTermChanges={policyChangesVehicleVm.coverageTermChanges}
                            coverageTermUpdated={coverageTermUpdated}
                            coverageTermErrorMessages={coverageValidationErrors}
                        />
                    </div>
                );
            }
        });

        return (
            <Fragment>
                { content }
            </Fragment>
        );
    };

    const getVehicleCoveragesContent = (vehicle) => {
        return (
            <Fragment>
                <h3 className={classNames(styles['wmic-subhead-h3'], styles['uppercaseText'])}>
                    { translator(messages.selectVehicleCoveragesForYour) }
                    <br />
                    {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                </h3>
                { vehicle?.vehicleType === CONSTANTS.VEHICLE_TYPES.TRAILER && (
                    <div className={classNames("ww-card ww-card__content ww-card-footer-group ww-card-notice", styles['wmic-trailer-notification'])}>
                        <div className="ww-card-notification-header">
                            <p>{ translator(messages.insuredTrailerLimits) }</p>
                        </div>
                        <ul>
                            <li>
                                { translator(messages.trailersMustHaveMatchingCollisionAndComprehensiveCoverageDeductibles) }
                            </li>
                            <li>
                                { translator(messages.deductiblesCannotBeLoweredFromTheirCurrentAmountsOnlineForAssistancePleaseCall) }
                                <a onClick={(e) => phoneTrigger(e, translator(messages.assistancePhoneNumber))}>{ translator(messages.assistancePhoneNumber) }</a>
                                { translator(messages.assistanceHours) }
                            </li>
                        </ul>
                    </div>
                )}
                { getCoverageDetail(editableCoverages) }
                <div className={classNames('ww-edit-inline-actions', styles['wmic-button-wrapper-div'])}>
                    <WMICButton type="outlined" onClick={ () => { cancelEditCoverages(model, policyChanges); }}>
                        { translator(messages.cancel) }
                    </WMICButton>
                    <WMICButton type="primary" onClick={save}>
                        { translator(messages.saveAndContinue) }
                    </WMICButton>
                </div>
            </Fragment>
        );
    };

    const overrideProps = {
        vehicleListView: {
            visible: viewMode == VIEW_MODES.VEHICLE_LIST_VIEW && !loadingState
        },
        editVehicleCoveragesTitle: {
            content: getTitleWithIcon(translator(messages.editVehicleCoverages), LobIconUtil.getWmicIcon('PersonalAuto'))
        },
        vehiclesSection: {
            content: getVehiclesSection(model)
        },
        buttonsSection: {
            content: getButtonsSection()
        },
        vehicleCoveragesView: {
            visible: viewMode == VIEW_MODES.EDIT_VEHICLE_COV_VIEW && !loadingState
        },
        vehicleCoveragesTitle: {
            content: getTitleWithIcon(translator(messages.vehicleCoverages), 'fa-file-text')
        },
        vehicleCoveragesContent: {
            content: getVehicleCoveragesContent(editedVehicle)
        },
        loadingQuoteView: {
            visible: viewMode == VIEW_MODES.LOADING_QUOTE_VIEW && !loadingState
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPreparingQuoteComponent: WMICPreparingQuoteLoader
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICChangeDeductiblesOrCoveragesPAComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    policyNumber: PropTypes.string.isRequired,
    termNumber: PropTypes.string.isRequired,
};

export default WMICChangeDeductiblesOrCoveragesPAComponent;