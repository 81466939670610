/* eslint-disable no-secrets/no-secrets */
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WMICDocuments from './components/WMICDocuments/WMICDocuments';
import WMICPremiumCosts from './components/WMICPremiumCosts/WMICPremiumCosts';
import WMICQuoteSummary from './components/WMICQuoteSummary/WMICQuoteSummary';
import WMICQuoteAlertMessage from './components/WMICQuoteAlertMessage/WMICQuoteAlertMessage';
import WMICFlexAlertMessage from './components/WMICFlexAlertMessage/WMICFlexAlertMessage';
import WMICQuoteValidations from './components/WMICQuoteValidations/WMICQuoteValidations';
import WMICPolicyChangeDiffComponent from './components/WMICPolicyChangeDiffComponent/WMICPolicyChangeDiffComponent'
import WMICLossHistoryEntryComponent from './components/WMICLossHistoryEntryComponent/WMICLossHistoryEntryComponent';

setComponentMapOverrides(
    {
        WMICDocuments: { component: 'WMICDocuments' },
        WMICPremiumCosts: { component: 'WMICPremiumCosts' },
        WMICQuoteSummary: { component: 'WMICQuoteSummary' },
        WMICQuoteAlertMessage: { component: 'WMICQuoteAlertMessage' },
        WMICFlexAlertMessage: { component: 'WMICFlexAlertMessage' },
        WMICQuoteValidations: { component: 'WMICQuoteValidations' },
        WMICPolicyChangeDiffComponent: { component: 'WMICPolicyChangeDiffComponent' },
        WMICLossHistoryEntryComponent: { component: 'WMICLossHistoryEntryComponent' },
    },
    {
        WMICDocuments,
        WMICPremiumCosts,
        WMICQuoteSummary,
        WMICQuoteAlertMessage,
        WMICFlexAlertMessage,
        WMICQuoteValidations,
        WMICPolicyChangeDiffComponent,
        WMICLossHistoryEntryComponent
    }
);

export { default as WMICInsuranceHistoryPoliciesComponent } from './components/WMICInsuranceHistoryPolicies/WMICInsuranceHistoryPoliciesComponent';
export { default as WMICDocuments } from './components/WMICDocuments/WMICDocuments';
export { default as WMICPremiumCosts } from './components/WMICPremiumCosts/WMICPremiumCosts';
export { default as WMICQuoteSummary } from './components/WMICQuoteSummary/WMICQuoteSummary';
export { default as WMICPolicyDetailsComponent } from './components/WMICPolicyDetailsComponent/WMICPolicyDetailsComponent';
export { default as WMICNamedInsuredComponent } from './components/WMICNamedInsuredComponent/WMICNamedInsuredComponent';
export { default as WMICQuoteAlertMessage } from './components/WMICQuoteAlertMessage/WMICQuoteAlertMessage';
export { default as WMICFlexAlertMessage } from './components/WMICFlexAlertMessage/WMICFlexAlertMessage';
export { default as WMICQuoteValidations } from './components/WMICQuoteValidations/WMICQuoteValidations';
export { default as WMICBrokerFeesComponent } from './components/WMICBrokerFeesComponent/WMICBrokerFeesComponent';
export { default as WMICFlexProgramComponent } from './components/WMICFlexProgramComponent/WMICFlexProgramComponent';
export { default as WMICPolicyCancelModal } from './components/WMICPolicyCancelModal/WMICPolicyCancelModal';
export { default as WMICPolicyChangeDiffComponent } from './components/WMICPolicyChangeDiffComponent/WMICPolicyChangeDiffComponent';
export { default as WMICLossHistoryEntryComponent } from './components/WMICLossHistoryEntryComponent/WMICLossHistoryEntryComponent';
export { default as WMICWizardHandler } from './utils/WMICWizardHandler';
export { default as useFlexAndBrokerRecalculate } from './utils/useFlexAndBrokerRecalculate';
export { default as WMICPolicyChangeUtil } from './utils/WMICPolicyChangeUtil';
export { default as WMICGoogleTagManagerUtil } from './utils/WMICGoogleTagManagerUtil';
export { default as WMICDTOError } from './components/WMICDTOError/WMICDTOError';
export { default as WMICCreditConsentPNIComponent } from './components/WMICCreditConsent/WMICCreditConsentPNIComponent/WMICCreditConsentPNIComponent';
export { default as WMICCreditConsentANIComponent } from './components/WMICCreditConsent/WMICCreditConsentANIComponent/WMICCreditConsentANIComponent';
