import { defineMessages } from 'react-intl';

export default defineMessages({
    dateRequested: {
        id: 'policy.pa.views.pa-drivers-details.driver-dash-view.Date Ordered',
        defaultMessage: 'Date Ordered',
    },
    dateReceived: {
        id: 'policy.pa.views.pa-drivers-details.driver-dash-view.Date Received',
        defaultMessage: 'Date Received',
    },
    orderStatus: {
        id: 'policy.pa.views.pa-drivers-details.driver-dash-view.Status',
        defaultMessage: 'Status',
    },
    sourceSystem: {
        id: 'policy.pa.views.pa-drivers-details.driver-dash-view.Source',
        defaultMessage: 'Source',
    },
});
