import React, {
    useContext
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import { BurgerMenuLink } from '@jutro/router';
import { useModal } from '@jutro/components';
import WMICErrorModal from 'wmic-pe-capability-quoteandbind-common-react/components/WMICErrorModal/WMICErrorModal';
import messages from './WMICCoBrowseButton.messages';
import styles from './WMICCoBrowseButton.module.scss';

const WMICCoBrowseButton = ({ isMobile }) => {
    const translator = useContext(TranslatorContext);
    const modalApi = useModal();
    const cobrowseSDKAvailable = !!window.Genesys;

    const showErrorModal = () => {
        modalApi.showModal(<WMICErrorModal message={messages.wmicCoBrowseUnavailableMessage} showAdditionalErrorInfo={false} />).catch(_.noop);
    };

    const handleOnClick = () => {
        if (cobrowseSDKAvailable) {
            Genesys("command", "Messenger.open",
                {},
                function () {
                    /*fulfilled callback*/
                },
                function () {
                    /*rejected callback*/
                    window.Genesys("command", "Messenger.close");
                }
            );
        }
        else {
            showErrorModal();
        }
    }

    return (isMobile ?
        <BurgerMenuLink id="cobrowseMenuItem" key="cobrowseMenuItem" isContainerOnly onClick={handleOnClick}>
            <span>{translator(messages.wmicCoBrowseButton)}</span>
        </BurgerMenuLink>
        : <div className={styles.cobrowseSubHeaderBtnParent}>
            <button className={styles.cobrowseSubHeaderBtn} onClick={handleOnClick} type="button" role="menuitem">{translator(messages.wmicCoBrowseButton)}</button>
        </div>);
}

WMICCoBrowseButton.defaultProps = {
    isMobile: false
};

export default WMICCoBrowseButton;