import LRUCache from 'lru-cache';
import { WMICLogger } from 'wmic-pe-portals-utils-js';
import WMICJsonRPCService from './WMICJsonRPCService';

export default class WMICCachedJsonRPCService {

    /**
     * Send a JSON-RPC request to the specified URL
     * @param {string} url - The URL to send the request to
     * @param {string} method - The method to invoke on the backend endpoint
     * @param {any} data - The data to be passed as part of the invokation on the backend
     * @param {Object} [additionalHeaders] - Additional headers to pass to the backend (e.g. auth)
     * @param {number} [cacheTimeoutInMilliseconds] - The time in milliseconds to cache the response (default 1 hour)
     * @returns {Promise} The promise from the backend call
     * @memberof WMICCachedJsonRPCService
     * @static
     * @public
     * @async
     * @method
     * @throws {Error} - If the request fails
     * @throws {Error} - If the response is not JSON
     *
     * */
    static send(url, method, data, additionalHeaders={}, cacheTimeoutInMilliseconds=3600000) {
        let responsePromise = null;
        const key = `url=${url}method=${method}data=${JSON.stringify(data)}`;
        if(this.cache.has(key)) {
            WMICLogger.debug(`key=${key} request=cache`);
            responsePromise = Promise.resolve(this.cache.get(key));
        } else {
            responsePromise = WMICJsonRPCService.send(url, method, data, additionalHeaders)
                .then((response) => {
                    WMICLogger.debug(`key=${key} request=cache set`);
                    this.cache.set(key, response, { ttl: cacheTimeoutInMilliseconds });
                    return Promise.resolve(response);
                });
        }
        return responsePromise;
    }
}

WMICCachedJsonRPCService.cache = new LRUCache( { max: 64 });
