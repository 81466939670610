/* eslint-disable indent */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import WMICRemoveVehicleMessage from '../../WMICRemoveVehicleMessage/WMICRemoveVehicleMessage';
import WMICRemoveVehicleUsingReviewStep from './WMICRemoveVehicleUsingReviewStep';
import { getVehicleName} from '../WMICRemoveVehicleWizardUtils';
import { VIEW_KEYS,  DATE_FORMAT, STEPS, MESSAGE_TYPES, STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';
import messages from '../WMICRemoveVehicleWizard.messages';


const getVehicleUsingReviewContent = (modelState, viewStateDispatch) => {
    return (
        <WMICRemoveVehicleUsingReviewStep modelState={modelState} viewStateDispatch={viewStateDispatch} />
    );
};

export const getMessageContent = (type, translator, intlContext, modelState, policy, viewStateDispatch, checkResult) => {
    const formattedNumber = (amount) => intlContext ? intlContext.formatNumber(amount) : amount;
    switch (type) {
        case MESSAGE_TYPES.EFFECTIVE_DATE: {
            if (!checkResult.isInPolicyRange) {
                return ({
                    title: translator(messages.pleaseCallUsToComplete),
                    text: translator(messages.isInPolicyRangeErrorMessage,
                        {
                            date: moment(modelState[STATE_KEYS.EFFECTIVE_DATE]).format(DATE_FORMAT),
                            effective: moment(policy.currentPeriod.effective).format(DATE_FORMAT),
                            expiration: moment(policy.currentPeriod.expiration).format(DATE_FORMAT)
                        }),
                    additionalTexts: [translator(messages.pleaseCallUsAtMessage)],
                    mode: 'error'
                });
            }

            if (!checkResult.isDiffInRange) {
                return ({
                    title: translator(messages.pleaseCallUsToComplete),
                    text: translator(messages.isDiffInRangeErrorMessage,
                        { date: moment(modelState[STATE_KEYS.EFFECTIVE_DATE]).format(DATE_FORMAT) }),
                    additionalTexts: [translator(messages.pleaseCallUsAtMessage)],
                    mode: 'error'
                });
            }

            if (checkResult.diffDays < 0) {
                return ({
                    title: translator(messages.pleaseCallUsToComplete),
                    text: translator(messages.dateInFutureErrorMessage,
                        { date: moment(modelState[STATE_KEYS.EFFECTIVE_DATE]).format(DATE_FORMAT) }),
                    additionalTexts: [translator(messages.pleaseCallUsAtMessage)],
                    mode: 'error'
                });
            }

            if (checkResult.hasFutureTransaction) {
                return ({
                    title: translator(messages.pleaseCallUsToComplete),
                    text: translator(messages.hasFutureTransactionErrorMessage),
                    additionalTexts: [translator(messages.pleaseCallUsForAssistanceAdditionalMessage)],
                    mode: 'error'
                });
            }

            break;
        }
        case MESSAGE_TYPES.VEHICLE_USING: {
            return (
                {
                    title: translator(messages.thanksWeAreAlmostThere),
                    text: translator(messages.youNeedToConfirmRemainingVehicle),
                    mode: 'info'
                }
            );
        }
        case MESSAGE_TYPES.VEHICLE_MILEAGE_REDUCED: {
            if (checkResult.isMileageReduced) {
                return ({
                    title: translator(messages.pleaseCallUsToComplete),
                    text: translator(messages.isMileageReducedMessage),
                    additionalTexts: [translator(messages.pleaseCallUsAtMessage)],
                    mode: 'error'
                });
            }
            break;
        }
        case MESSAGE_TYPES.VEHICLE_USING_REVIEW: {
            let multiError = checkResult.isChangeToBusiness ? 1 : 0;
            multiError += checkResult.isChangeUnCorrectPrimaryUseList?.length > 0 ? 1 : 0;

            if (multiError > 1) {
                const displayMessage = {
                    title: translator(messages.correctTheFollowing),
                    text: translator(messages.multipleErrors),
                    mode: 'error',
                    editBlock: () => getVehicleUsingReviewContent(modelState, viewStateDispatch),
                    additionalTexts: []
                };

                if (checkResult.isChangeToBusiness) {
                    displayMessage.additionalTexts.push(translator(messages.changeToBusiness));
                }
                if (checkResult.isChangeUnCorrectPrimaryUseList.length > 0) {
                    checkResult.isChangeUnCorrectPrimaryUseList.forEach((v) => {
                        displayMessage.additionalTexts.push(translator(messages.indicatedChange, { vehicleName: v.displayName, existingUsage: v.originalPrimaryUse, futureUsage: v.currentPrimaryUse }));
                    })
                }
                displayMessage.additionalTexts.push(translator(messages.assistantPhoneNumber));
                return displayMessage;
            }

            if (checkResult.isChangeToBusiness) {
                return ({
                    title: translator(messages.correctTheFollowing),
                    text: translator(messages.removingCarUsedForBusiness,
                        { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]), annualMileage: formattedNumber(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.annualMileage) }),
                    mode: 'error',
                    additionalTexts: [translator(messages.assistantPhoneNumber)],
                    editBlock: () => getVehicleUsingReviewContent(modelState, viewStateDispatch)
                });
            }

            if (checkResult.isChangeUnCorrectPrimaryUseList?.length > 0) {
                const displayMessage = {
                    title: translator(messages.correctTheFollowing),
                    mode: 'error',
                    editBlock: () => getVehicleUsingReviewContent(modelState, viewStateDispatch),
                    additionalTexts: []
                };

                if (checkResult.isChangeUnCorrectPrimaryUseList.length == 1) {
                    const v = checkResult.isChangeUnCorrectPrimaryUseList[0];
                    displayMessage.text = translator(messages.indicatedChange, { vehicleName: v.displayName, existingUsage: v.originalPrimaryUse, futureUsage: v.currentPrimaryUse });
                    displayMessage.additionalTexts.push(translator(messages.vehicleUseChangeAssistantPhoneNumber));
                } else {
                    displayMessage.text = translator(messages.multipleErrors);
                    checkResult.isChangeUnCorrectPrimaryUseList.forEach((v) => {
                        displayMessage.additionalTexts.push(translator(messages.indicatedChange, { vehicleName: v.displayName, existingUsage: v.originalPrimaryUse, futureUsage: v.currentPrimaryUse }));
                    })
                    displayMessage.additionalTexts.push(translator(messages.vehicleUseChangeAssistantPhoneNumber));
                }
                return displayMessage;
            }
            break;
        }
        case MESSAGE_TYPES.PRIMARY_DRIVERS_ASSIGNMENT: {
                return ({
                    title: translator(messages.correctTheFollowing),
                    text: translator(messages.existingVehicleAssignedUniquePrimaryDriver),
                    additionalTexts: [translator(messages.assistantPhoneNumber)],
                    mode: 'error'
                });

        }
        case MESSAGE_TYPES.INSURED_OR_SPOUSE_REG_OWNER: {
            return ({
                title: translator(messages.pleaseCallUsToComplete),
                text: translator(messages.paInsuredOrSpouseRegOwnerForVehicle),
                additionalTexts: [translator(messages.pleaseCallUsForAssistanceMessage)],
                mode: 'error'
            });

        }
        case MESSAGE_TYPES.LAST_EFFECTIVE_DAY_OF_TERM_BEFORE_RENEWAL: {
            return ({
                title: translator(messages.pleaseCallUsToComplete),
                text: translator(messages.yourPolicyIsScheduledToRenewOnErrorMessage,
                    { date: moment(policy.renewedPeriod?.effective).format(DATE_FORMAT) }),
                additionalTexts: [translator(messages.pleaseCallUsToCompleteRequestMessage)],
                mode: 'error'
            });
        }
        case MESSAGE_TYPES.ATLEAST_ONE_VEHICLE_ASSIGNED_TO_DRIVER: {
            return ({
                title: translator(messages.correctTheFollowing),
                text: translator(messages.atleastOneVehicleAssignedToDriver),
                additionalTexts: [translator(messages.assistantPhoneNumber)],
                mode: 'error'
            });

        }
        default: {
            return ({
                title: translator(messages.pleaseCallUsToComplete),
                text: translator(messages.somethingWentWrong),
                additionalTexts: [translator(messages.pleaseCallUsForAssistanceMessage)],
                mode: 'error'
            });
        }
    }
};

const WMICRemoveVehicleErrorStep = (props) => {
    const { errorMessage, ...otherProps } = props;
    const translator = useTranslator();
    
    let errorMessageContent = errorMessage;
    if (!errorMessage) {
        errorMessageContent = getMessageContent('defaultError', translator);
    }

    return (
        <div {...otherProps}>
            {errorMessageContent && <WMICRemoveVehicleMessage {...errorMessageContent} />}
            {errorMessageContent && errorMessageContent.editBlock && errorMessageContent.editBlock()}
        </div>
    );
}

WMICRemoveVehicleErrorStep.propTypes = {
    errorMessage: PropTypes.shape({})
};

export default WMICRemoveVehicleErrorStep;
