
import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWizardModals, WMICWizardChangeOrRenewalPage } from 'wmic-pe-portals-wizard-components-ui';
import { DropdownMenuButton } from '@jutro/components';
import {
    CONSTANTS,
    POLICY_DETAILS,
    WMICUserAccessUtil,
    WMICDriverUtil,
    WMICLogger,
    MODAL_CONSTANTS
} from 'wmic-pe-portals-utils-js';
import { WMICDriverDetailView } from 'wmic-pe-capability-gateway-common-pa-react';
import { EntityUtil } from '@xengage/gw-portals-util-js';
import { messages as commonMessagesGW } from '@xengage/gw-platform-translations';
import { messages as commonMessages} from 'wmic-pe-capability-gateway-policyrenewal-common-react';

import metadata from './WMICPAPolicyRenewalDriversDetailsPage.metadata.json5';
import messages from './WMICPAPolicyRenewalDriversDetailsPage.messages';
import {WMICErrorHandler} from "wmic-pe-capability-quoteandbind-common-react";

import { DropdownMenuLink } from '@jutro/router';

const ANIS_PATH = 'baseData.additionalNamedInsureds_WMIC';
const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
const { INSURED_ROLES } = CONSTANTS;

const createDriver = (index, skeletonStruct, person, insuredRelation) => {
    if (!person) {
        person = {
            tempID: EntityUtil.nextId()
        }
    }

    const driver = {
        person: { ...person },
        firstName: person.firstName,
        middleName: person.middleName,
        lastName: person.lastName,
        displayName: `${person.firstName} ${person.lastName}`,
        dashReports_Ext: [],
        dateOfBirth: person.dateOfBirth,
        driverClassHistory_WMIC: [],
        getStateLicThirtyDay_WMIC: null,
        accountHolder: insuredRelation === INSURED_ROLES.PRIMARY_INSURED,
        mvrReports_WMIC: [],
        mvrReport_WMIC: {
            mvrIncidents_WMIC: []
        },
        licenseCountry: 'CA',
        policyHistory_WMIC: {
            previousInsurances: []
        },
        mvrAddressMatchesInfo: null,
        isPrimaryInsured_WMIC: insuredRelation === INSURED_ROLES.PRIMARY_INSURED,
        isAdditionalNamedInsured_WMIC: insuredRelation === INSURED_ROLES.ADDITIONAL_INSURED,
    };

    // this is the hookup for server aspects
    if (skeletonStruct.length > 0 && skeletonStruct[0].metaDataMap) {
        driver.metaDataMap = skeletonStruct[0].metaDataMap;
    }

    driver.tempID = EntityUtil.nextId();

    if (person) {
        if (person.publicID) {
            _.set(driver, 'person.publicID', person.publicID);
        }

        if (person.primaryAddress) {
            _.set(driver, 'licenseState', person.primaryAddress.state);
        }
    }

    return driver;
};

function WMICPAPolicyRenewalDriversDetailsPage(props) {
    const {
        wizardData: policyRenewalVM,
        updateWizardData
    } = props;
    const { setWizardLoading, showConfirm, showError } = useWizardModals();
    const { RenewalService } = useDependencies('RenewalService');
    const { authHeader, authUserData } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [skeletonStruct, setSkeletonStruct] = useState({});
    const [additionalNamedInsureds, setAdditionalNamedInsureds] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { initialValidation, isComponentValid, onValidate, registerInitialComponentValidation } = useValidation(
        'WMICPolicyRenewalPADriversDetailsPage'
    );
    const driversWithDuplicateLicence  = WMICDriverUtil.getDriversWithDuplicateLicense(_.get(policyRenewalVM.value, DRIVERS_PATH));
    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);

    const initDriverData = useCallback(async () => {
        const driversTmp = _.get(policyRenewalVM, DRIVERS_PATH);
        const additionalNamedInsuredsSub = _.get(policyRenewalVM, `${ANIS_PATH}.value`);
        const additionalNamedInsuredsTmp = [];
        const skeletonStructure = await RenewalService.getSkeletonStructure(
            policyRenewalVM.value,
            authHeader
        );

        // pull out driver specific structure
        const driverStruct = _.get(skeletonStructure, DRIVERS_PATH)

        setSkeletonStruct(driverStruct);

        if (additionalNamedInsuredsSub && !_.isEmpty(additionalNamedInsuredsSub)) {
            additionalNamedInsuredsSub.forEach((nameInsured) => {
                const additionalNameInsured = viewModelService.create(nameInsured, 'pc', 'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC');

                _.set(additionalNameInsured, 'isDriver', false);

                const aniSubType = _.get(additionalNameInsured, 'subtype.value.code');

                if (aniSubType === CONSTANTS.Person.toLowerCase()) {
                    const isDriver = driversTmp.value.some((driver) => _.get(additionalNameInsured, 'contactPublicID.value') === _.get(driver, 'person.publicID.value'));

                    _.set(additionalNameInsured, 'isDriver', isDriver);
                }

                additionalNamedInsuredsTmp.push(additionalNameInsured);
            });
            if (!_.isEmpty(additionalNamedInsuredsTmp)) {
                setAdditionalNamedInsureds(additionalNamedInsuredsTmp);
            }
        }

        const primaryNamedInsuredTmp = _.get(policyRenewalVM, 'baseData.primaryNamedInsured_WMIC.value');

        const isDriver = driversTmp.value.some((driver) => _.get(primaryNamedInsuredTmp, 'contactPublicID') === _.get(driver, 'person.publicID'));

        _.set(primaryNamedInsuredTmp, 'isDriver', isDriver);

    }, [RenewalService, authHeader, policyRenewalVM, viewModelService])

    useEffect(() => {
        registerInitialComponentValidation(() => _.get(policyRenewalVM, DRIVERS_PATH).value.length > 0);

        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        initDriverData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCreateDriver = useCallback((index, namedInsured, insuredRelation) => {
        const driver = createDriver(index, skeletonStruct, namedInsured, insuredRelation);
        const driversVM = _.get(policyRenewalVM, DRIVERS_PATH);
        const { _xCenter, _dtoName } = driversVM;
        const driverVM = viewModelService.create(driver, _xCenter, _dtoName, policyRenewalVM.aspects.context());
        const commercialDriverVM = viewModelService.create({}, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.CommercialDriverDTO_WMIC').value;

        _.set(driverVM, 'commercialDriver_WMIC', commercialDriverVM);
        _.set(driverVM, 'policyPeriodEndDate_WMIC', policyRenewalVM.baseData.periodEndDate.value);

        if (namedInsured) {
            _.set(driverVM, 'assignment_WMIC.value', _.find(
                driverVM.assignment_WMIC.aspects.availableValues,
                (assignmentType) => assignmentType.code === CONSTANTS.ASSIGNMENT.ASSIGNED));

            if (policyRenewalVM.baseData.primaryNamedInsured_WMIC.subtype.value === CONSTANTS.Person
                && policyRenewalVM.baseData.primaryNamedInsured_WMIC.publicID.value === driverVM.person.publicID.value) {
                driverVM.creditConsentReceived = policyRenewalVM.baseData.creditConsentReceived.value;
                driverVM.creditConsentDate = policyRenewalVM.baseData.creditConsentDate.value;
                driverVM.personalInfoConsentForm = (!_.isUndefined(policyRenewalVM.baseData.personalInfoConsentForm.value) ?
                    policyRenewalVM.baseData.personalInfoConsentForm.value : null);
                driverVM.creditInfoWithdrawalConsent = (!_.isUndefined(policyRenewalVM.baseData.creditInfoWithdrawalConsent.value) ?
                    policyRenewalVM.baseData.creditInfoWithdrawalConsent.value : null);
            }

            const ANI = _.find(policyRenewalVM.baseData.additionalNamedInsureds_WMIC.value, (namedInsuredParam) => namedInsuredParam.contactPublicID === driverVM.person.publicID.value || namedInsuredParam.publicID === driverVM.person.publicID.value);

            if (ANI) {
                driverVM.creditConsentReceived = ANI.creditConsentReceived;
                driverVM.creditConsentDate = ANI.creditConsentDate;
                driverVM.personalInfoConsentForm = (!_.isUndefined(ANI.personalInfoConsentForm) ? ANI.personalInfoConsentForm : null);
                driverVM.creditInfoWithdrawalConsent = (!_.isUndefined(ANI.creditInfoWithdrawalConsent) ? ANI.creditInfoWithdrawalConsent : null);
                driverVM.person.publicID = ANI.contactPublicID;
            }

            if (policyRenewalVM.baseData.primaryNamedInsured_WMIC.publicID.value === driverVM.person.publicID.value) {
                _.set(driverVM, 'priInsuredRelation_wmic.value', CONSTANTS.PRIMARY_INSURED_RELATIONSHIP.INSURED)
            } else {
                _.set(driverVM, 'priInsuredRelation_wmic.value', namedInsured.relationshipToPrimaryInsured_WMIC)
            }
        }

        const today = new Date();

        driverVM.minDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MAXIMUM_LEGAL_AGE, month: today.getMonth(), day: today.getDate()
        };
        driverVM.maxDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MINIMUM_LEGAL_AGE, month: today.getMonth(), day: today.getDate()
        };
        policyRenewalVM.lobData.personalAuto.coverables.drivers.value.push(driverVM.value);

        return driverVM;
    }, [skeletonStruct, policyRenewalVM, viewModelService]);

    const onCreateDriverWithModal = useCallback((index, namedInsured, insuredRelation) => showConfirm({
        title: commonMessages.revalidationRequiredTitle,
        message: commonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessagesGW.yes,
        cancelButtonText: commonMessagesGW.cancelModel,
    }).then((results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(policyRenewalVM);
        }
        return onCreateDriver(index, namedInsured, insuredRelation);
    }),
    // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maintainFurtherStepsVisitedSubmitted, onCreateDriver, policyRenewalVM, setIsCustomStepsInvalidated, showConfirm]);

    const saveRenewal = useCallback(async (newPolicyRenewal, onError = _.noop) => {

        _.unset(newPolicyRenewal, 'bindData');

        try {
            setWizardLoading(true, translator(messages.savingDriver));

            const result = await RenewalService.saveRenewal(
                [newPolicyRenewal],
                authHeader
            );

            _.extend(newPolicyRenewal, result);
            policyRenewalVM.value = newPolicyRenewal;

            updateWizardData(policyRenewalVM);
            setShowErrors(false);

            return true;
        } catch (error) {
            WMICLogger.error('Save driver failed', error);
            onError();

            return false;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, RenewalService, authHeader, policyRenewalVM])


    const onSaveDriver = useCallback(() => saveRenewal(policyRenewalVM.value), [policyRenewalVM.value, saveRenewal]);

    const onSaveDriverMiddleware = useCallback((selectedDriver) => {
        if (driversWithDuplicateLicence ) {
            const otherDriverWithSameLicense = driversWithDuplicateLicence .find(d => d.publicID !== selectedDriver?.value?.publicID) || driversWithDuplicateLicence [0];

            showError({
                title: translator(messages.licenseNotUnique),
                message: translator(messages.licenceMatchMsg, {driver: otherDriverWithSameLicense.displayName})
            })
        }
    }, [driversWithDuplicateLicence , showError, translator]);

    const deleteDriver = useCallback(async (driverId) => {

        _.unset(policyRenewalVM.value, 'bindData');

        try {
            setWizardLoading(true, translator(messages.removingDriver));

            const removeDriverResult = await RenewalService.removeDriver(
                policyRenewalVM.jobID.value,
                driverId,
                authHeader
            );

            if (!_.isEmpty(removeDriverResult.errorMessages)) {
                showError({
                    title: translator(messages.removingDriverErrorModalTitle),
                    message: removeDriverResult.errorMessages.join('\n')
                });
                return false;
            }

            policyRenewalVM.value = removeDriverResult.renewalData;

            updateWizardData(policyRenewalVM);
            setShowErrors(false);

            return true;
        } catch (error) {
            WMICLogger.error('Save driver failed', error);
            WMICErrorHandler.processAsModal(error, policyRenewalVM.value.jobID, policyRenewalVM.value.baseData.jobType);

            return false;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, RenewalService, authHeader, showError, policyRenewalVM]);


    const onDeleteDriver = useCallback(driver => {
        const modalMsg = translator(messages.removeDriver, { driver: driver.displayName?.value });

        showConfirm({
            title: messages.removeDriverTitle,
            message: modalMsg,
            confirmButtonText: commonMessagesGW.yes,
            cancelButtonText: commonMessagesGW.noModel
        }).then(async (response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                return deleteDriver(driver.publicID?.value);
            }
        }).catch(_.noop);
    }, [translator, showConfirm, deleteDriver]);

    const onCancelDriver = () => {
        _.set(policyRenewalVM, 'isEditingPage.value', false);
        updateWizardData(policyRenewalVM);
    }

    const onNext = useCallback(async () => {
        if (!isComponentValid || !WMICDriverUtil.presentDriverList(policyRenewalVM)) {
            setShowErrors(true);

            return false;
        }

        if (WMICUserAccessUtil.canCreateSubmission(authUserData.roles)) {
            try {
                setWizardLoading(true, translator(commonMessages.savingTransactionDetails));

                policyRenewalVM.value = await RenewalService.saveRenewal(
                    [policyRenewalVM.value],
                    authHeader
                );

                updateWizardData(policyRenewalVM);

                return policyRenewalVM;

            } finally {
                setWizardLoading(false);
            }
        }

        return false;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, authUserData.roles, setWizardLoading, translator, policyRenewalVM, RenewalService, authHeader]);


    const renderAddDriverButton = useCallback(({ isEditing, onClick }) => {
        const drivers = _.get(policyRenewalVM, `${DRIVERS_PATH}.value`, []);
        const pni = _.get(policyRenewalVM, 'baseData.primaryNamedInsured_WMIC.value');

        return (<DropdownMenuButton
            id="addNewDriverButton"
            buttonText={translator({
                "id": "quoteandbind.pa.views.pa-drivers-details.addNewDriver",
                "defaultMessage": "meta.Add New Driver"
            })}
            icon="mi-add"
            disabled={isEditing} >
            {WMICDriverUtil.canContactBeAddedToDrivers(drivers, pni) && <DropdownMenuLink
                onClick={() => onClick(
                    drivers.length,
                    pni,
                    INSURED_ROLES.PRIMARY_INSURED)}>
                {pni.displayName}
            </DropdownMenuLink>}
            {additionalNamedInsureds.map((ani, index) => (
                WMICDriverUtil.canContactBeAddedToDrivers(drivers, ani.value) && <DropdownMenuLink
                    onClick={() => onClick(
                        drivers.length,
                        _.get(policyRenewalVM, `${ANIS_PATH}.value[${index}]`),
                        INSURED_ROLES.ADDITIONAL_INSURED)}>
                    {ani.displayName.value}
                </DropdownMenuLink>
            ))}
            <DropdownMenuLink onClick={() => onClick(
                _.get(policyRenewalVM, `${DRIVERS_PATH}.value`, []).length
            )}>{translator(messages.addNewDriver)}</DropdownMenuLink>
        </DropdownMenuButton>)
    }, [additionalNamedInsureds, policyRenewalVM, translator]);

    const overrideProps = {
        atLeastOneMsgContainer: {
            visible: _.isEmpty(_.get(policyRenewalVM, `${DRIVERS_PATH}.value`, [])),
            message: messages.atLeastOne
        },
        requiredText: {
            visible: _.get(policyRenewalVM, DRIVERS_PATH).length > 0
        },
        driverListView: {
            value: _.get(policyRenewalVM, `${DRIVERS_PATH}.children`, []),
            clickable: true,
            startOpen: true,
            readOnly: isReadOnlyUser,
            VMData: [{
                headerText: translator(messages.driverName), path: 'displayName'
            },
            {
                headerText: translator(messages.licenseNumber), path: 'licenseNumber'
            },
            {
                headerText: translator(messages.licenseProvince), path: 'licenseState', getData: (row, path) => _.get(row.value, path) || translator(messages.licenseProvinceOther)
            }],
            toCreate: onCreateDriverWithModal,
            onSave: onSaveDriver,
            onSaveMiddleware: onSaveDriverMiddleware,
            onDelete: onDeleteDriver,
            toUndoCreate: () => {
                const drivers = _.get(policyRenewalVM.value, DRIVERS_PATH);

                drivers.splice(drivers.length - 1, 1);
                _.set(policyRenewalVM.value, DRIVERS_PATH, drivers);
                updateWizardData(policyRenewalVM);
            },
            detailViewComponent: WMICDriverDetailView,
            onValidate,
            renderAddButton: renderAddDriverButton,
            detailViewComponentProps: {
                jobVM: policyRenewalVM
            },
            onCancel: onCancelDriver,
            showCancelModal: true
        },
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyRenewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPAPolicyRenewalDriversDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICPAPolicyRenewalDriversDetailsPage);
