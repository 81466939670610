/* eslint-disable camelcase */
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICCachedJsonRPCService, WMICJsonRPCService } from 'wmic-pe-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method The method to invoke on the backend endpoint
 * @param {any} data The data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} The promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayPolicyCancellation'), method, data, additionalHeaders);
}

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method The method to invoke on the backend endpoint
 * @param {any} data The data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} The promise from the backend call
 */
function processCachedSubmission(method, data, additionalHeaders = {}) {
    return WMICCachedJsonRPCService.send(getProxiedServiceUrl('gatewayPolicyCancellation'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Policy Cancellation backend operations
 *
 * @export
 * @class GatewayCancellationService
 */
export default class GatewayCancellationService {

    /**
     * Creates a new policy cancellation transaction for the policy.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} policyCancellation The Policy Cancellation data to be passed to the backend
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static createPolicyCancellationTransaction(jobNumber, policyCancellation, additionalHeaders = {}) {
        return processSubmission('createPolicyCancellationTransaction', [jobNumber, policyCancellation], additionalHeaders);
    }

    /**
     * Initiates a new policy cancellation for the policy.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {String} termNumber The Policy Term Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static initCancellationDTO(jobNumber, termNumber, additionalHeaders = {}) {
        return processSubmission('initCancellationDTO_WMIC', [jobNumber, termNumber], additionalHeaders);
    }


    /**
     * Initiates a new policy cancellation for the policy.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {String} cancellationReasonCode The Cancellation Reason Code for the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static getAvailableCancellationOptions(jobNumber, cancellationReasonCode, additionalHeaders = {}) {
        return processCachedSubmission('getAvailableCancellationOptions', [jobNumber, cancellationReasonCode], additionalHeaders);
    }

    /**
     * Find the Job by JobNumber.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static findJobByJobNumber(jobNumber, additionalHeaders = {}) {
        return processSubmission('findJobByJobNumber', [jobNumber], additionalHeaders);
    }

    /**
     * Get the Cancellation Summary.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static getCancellationSummary(jobNumber, additionalHeaders = {}) {
        return processSubmission('getCancellationSummary', [jobNumber], additionalHeaders);
    }

    /**
     * Withdraws the Cancellation.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static withdrawCancellation(jobNumber, additionalHeaders = {}) {
        return processSubmission('withdrawCancellationByJobNumber', [jobNumber], additionalHeaders);
    }

    /**
     * Binds the Cancellation.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static bindCancellation(jobNumber, additionalHeaders = {}) {
        return processSubmission('bindCancellation', [jobNumber], additionalHeaders);
    }

    /**
     * Quotes the Cancellation.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static quoteCancellation(jobNumber, additionalHeaders = {}) {
        return processSubmission('quoteCancellation_WMIC', [jobNumber], additionalHeaders);
    }
    
    /**
     * Refers the cancellation to Underwriter.
     *
     * @param {String} jobNumber The Policy Number of the policy to be cancelled
     * @param {String} notesForUnderwriter Notes for underwriter
     * @param {Object} [additionalHeaders] Additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} The promise from the backend call
     * @memberof GatewayCancellationService
     */
    static referToUnderwriter(jobNumber, notesForUnderwriter, additionalHeaders = {}) {
        return processSubmission('referToUnderwriter', [jobNumber, notesForUnderwriter], additionalHeaders);
    }
}