import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICCachedJsonRPCService } from 'wmic-pe-portals-transport-js';

export default class WMICVinLookupService {

    static retrieveMakeList(vehicleType, language, additionalHeaders = {}) {
        return WMICCachedJsonRPCService.send(
            getProxiedServiceUrl('uiSupportingInfoLookup'),
            'retrieveMakeList',
            [vehicleType, language],
            additionalHeaders
        );
    }

    static retrieveModelList(vehicleType, make, year, language, additionalHeaders = {}) {
        return WMICCachedJsonRPCService.send(
            getProxiedServiceUrl('uiSupportingInfoLookup'),
            'retrieveModelsForMake',
            [vehicleType, make, year, language],
            additionalHeaders
        );
    }

    static vehicleSearch(vinSearchDto, additionalHeaders = {}) {
        return WMICCachedJsonRPCService.send(
            getProxiedServiceUrl('search'),
            'vehicleSearch',
            [vinSearchDto],
            additionalHeaders
        );
    }
}