import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicCoBrowseButton: {
        id: 'portal.index.CoBrowseButton',
        defaultMessage: 'Co-browse'
    },
    wmicCoBrowseUnavailableMessage: {
        id: 'portal.index.CoBrowseUnavailable',
        defaultMessage: 'This service is currently unavailable.  Please try again later.'
    }
});