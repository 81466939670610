import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICDashDetailView.metadata.json5';
import messages from './WMICDashDetailView.messages';

function WMICDashDetailView(props) {
    const { value: selectedEntry } = props;

    const overrideProps = {
        '@field': {
            parentNode: selectedEntry,
            readOnly: true,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedEntry}
            overrideProps={overrideProps}
        />
    );
}

WMICDashDetailView.propTypes = {
    selectedEntry: PropTypes.shape({}).isRequired,
};

export default WMICDashDetailView;
