import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingInformationDescription: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.description',
        defaultMessage: 'Description'
    },
    ratingInformationTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.title',
        defaultMessage: 'Rating Information'
    },
    ratingInfoText: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information',
        defaultMessage: 'Rating Information'
    },
    description: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.description',
        defaultMessage: 'Description'
    },
    vehicleTerritoryCode: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.territory',
        defaultMessage: 'Territory'
    },
    ratedGarageLocation: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.ratedGarageLocation',
        defaultMessage: 'Rated Garage Location'
    },
    annualMileage: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.annualMileage',
        defaultMessage: 'Kilometers - annually'
    },
    commutingMiles: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.commutingMiles',
        defaultMessage: 'Kilometers - to work one way'
    },
    primaryUse: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.primaryUse',
        defaultMessage: 'Use'
    },
    accidentBenefitRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.accidentBenefitRateGroup',
        defaultMessage: 'Accident Benefit Rate Group'
    },
    dcpdRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.dcpdRateGroup',
        defaultMessage: 'DCPD Rate Group'
    },
    civilLiabilityRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.civilLiabilityRateGroup',
        defaultMessage: 'Civil Liability Rate Group'
    },
    collisionRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.collisionRateGroup',
        defaultMessage: 'Collision Rate Group'
    },
    comprehensiveRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.comprehensiveRateGroup',
        defaultMessage: 'Comprehensive Rate Group'
    },
    priceRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.priceRateGroup',
        defaultMessage: 'Price Rate Group'
    },
    priceRateGroupMotorcycle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.priceRateGroupMotorcycle',
        defaultMessage: 'Motorcycle Rate Group'
    },
    abPriceRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.abPriceRateGroup',
        defaultMessage: 'AB Price Rate Group'
    },
    collisionPriceRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.collisionPriceRateGroup',
        defaultMessage: 'COL Price Rate Group'
    },
    compPriceRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.compPriceRateGroup',
        defaultMessage: 'COMP Price Rate Group'
    },
    dcpdPriceRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.dcpdPriceRateGroup',
        defaultMessage: 'DCPD Price Rate Group'
    },
    primaryDriverClass: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.primaryDriverClass',
        defaultMessage: 'Primary Driver Class'
    },
    occasionalDriverClass: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.occasionalDriverClass',
        defaultMessage: 'Occasional Driver Class' 
    },
    drivingRecordPrimaryDriver: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.drivingRecordPrimaryDriver',
        defaultMessage: 'Driving Record Primary Driver'
    },
    drivingRecordOccasionalDriver: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.drivingRecordOccasionalDriver',
        defaultMessage: 'Driving Record Occasional Driver'
    },
    vehicleDiscountsApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicleDiscountsApplied',
        defaultMessage: 'Discounts Applied'
    },
    vehicleSurchargesApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicleSurchargesApplied',
        defaultMessage: 'Surcharges Applied'
    },
    none: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.none',
        defaultMessage: 'NONE'
    },
    vehicleType: {
        id: "wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicle-type",
        defaultMessage: "Vehicle Type"
    },
    currentValue: {             
        id: "wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.current-value",
        defaultMessage: "Current value / purchase price"
    }
});
