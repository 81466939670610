import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICCachedJsonRPCService } from 'wmic-pe-portals-transport-js';

function processRequest(method, data, additionalHeaders) {
    return WMICCachedJsonRPCService.send(getProxiedServiceUrl('uiSupportingInfoLookup'), method, data, additionalHeaders);
}

export default class UISupportingInfoLookupService {
    static retrieveInsuranceCarriersList(dto, includeNoPrev, additionalHeaders = {}) {
        dto.push(includeNoPrev !== undefined ? includeNoPrev : false);

        return processRequest('retrieveInsuranceCarriersList', [...dto], additionalHeaders);
    }

    static retrieveConvictionList(dto, additionalHeaders = {}) {
        return processRequest('retrieveConvictionList', [dto], additionalHeaders);
    }

    static retrieveUserEffectiveRPCsForDate(asOf, additionalHeaders = {}) {
        return processRequest('retrieveUserEffectiveRPCsForDate', [asOf], additionalHeaders);
    }

    static retrieveRPCDates(additionalHeaders = {}) {
        return processRequest('retrieveRPCDates', [], additionalHeaders);
    }

    static retrieveConstants(asOf, additionalHeaders = {}) {
        return processRequest('retrieveConstants', [asOf], additionalHeaders);
    }

    static retrieveAvailableClassCodesForCommercialVehicle(
        baseState,
        rateAsOfDate,
        typeOfUse,
        subTypeOfUse,
        garageLocationState,
        vehicleType,
        towingVehicleClassCode,
        additionalHeaders = {}
    ) {
        return processRequest('retrieveAvailableClassCodesForCommercialVehicle', 
            [
                baseState,
                rateAsOfDate,
                typeOfUse,
                subTypeOfUse,
                garageLocationState,
                vehicleType,
                towingVehicleClassCode
            ], 
            additionalHeaders
        );
    }

    static retrieveAvailableBuildingMaterialTypes(
        ratingJurisdiction,
        rateAsOfDate,
        policyType,
        materialType,
        additionalHeaders = {}
    ) {
        return processRequest('retrieveAvailableBuildingMaterialTypes',
            [ratingJurisdiction, rateAsOfDate, policyType, materialType], additionalHeaders)
    }

    static retrieveNormalRadiusOfOperationByClass(additionalHeaders = {}) {
        return processRequest('retrieveNormalRadiusOfOperationByClass', [], additionalHeaders);
    }

    static retrieveCVRadiusOfOperationEnumGreaterThan(additionalHeaders = {}) {
        return processRequest('retrieveCVRadiusOfOperationEnumGreaterThan', [], additionalHeaders);
    }

    static retrieveIndustryCodes(jobNumber, code, classification = '', searchFieldsSeperately = false, additionalHeaders = {}) {
        return processRequest(
            'getIndustryCodesByCriteria',
            [{  code: code,
                classification: classification,
                searchFieldsSeperately_WMIC: searchFieldsSeperately,
                jobNumber: jobNumber
            }],
            additionalHeaders
        );
    }
}
