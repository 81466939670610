import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { SOURCE_SYSTEM } from 'wmic-pe-portals-utils-js';
import {
    ViewModelForm,
    useDataRefresh,
} from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './WMICLossHistoryEntryComponent.metadata.json5';
import messages from './WMICLossHistoryEntryComponent.messages.js';

function WMICLossHistoryEntryComponent(props) {
    const {
        id,
        selectedLossHistoryEntry,
        saveLossHistoryEntry,
        cancelLossHistoryEntry,
        onValidate,
        isEditMode,
        isStatusRequested,
        lossHistoryViewMode,
    } = props;

    const [currentLossHistoryEntry, updateCurrentLossHistoryEntry] = useState(selectedLossHistoryEntry);
    const [showErrors, updateShowErrors] = useState(false);
    const { refreshData } = useDataRefresh();
    const translator = useTranslator();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const isGPA = currentLossHistoryEntry.value.sourceSystem === SOURCE_SYSTEM.GPA;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        };
    }, [isComponentValid, onValidate, currentLossHistoryEntry, id]);

    useEffect(() => {
        updateCurrentLossHistoryEntry(selectedLossHistoryEntry);
    }, [selectedLossHistoryEntry]);

    const updateLossHistoryEntry = (data) => {
        refreshData();
        updateCurrentLossHistoryEntry(data);
    };

    const handleSaveLossHistoryEntry = () => {
        if (isComponentValid) {
            saveLossHistoryEntry(currentLossHistoryEntry);
        } else {
            updateShowErrors(true);
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: currentLossHistoryEntry,
        },
        dateRequested: {
            readOnly: isGPA || lossHistoryViewMode || !isEditMode,
        },
        dateReceived: {
            readOnly: true,
        },
        sourceSystem: {
            readOnly: true,
        },
        orderStatus: {
            visible: !isStatusRequested,
            readOnly: true,
        },
        requested: {
            visible: isStatusRequested,
            readOnly: true,
            value: translator(messages.requested),
        },
        WMICLossHistoryEntryForm: {
            isEditing: !lossHistoryViewMode,
            saveLabel: translator(isGPA ? messages.apply : messages.save),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveLossHistoryEntry,
            cancelLossHistoryEntry,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentLossHistoryEntry}
            overrideProps={overrideProps}
            onModelChange={updateLossHistoryEntry}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICLossHistoryEntryComponent.propTypes = {
    selectedLossHistoryEntry: PropTypes.shape({}).isRequired,
    saveLossHistoryEntry: PropTypes.func.isRequired,
    cancelLossHistoryEntry: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isStatusRequested: PropTypes.bool.isRequired,
    lossHistoryViewMode: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
};

export default WMICLossHistoryEntryComponent;